import { getDiscoveryUrl } from '@nubank/www-latam-commons/utils/environmentUtils';
import { fetchJson } from '@nubank/www-latam-commons/utils/http';

async function getDocumentsCoUrl() {
  const discoveryFetch = await fetchJson(getDiscoveryUrl());
  return discoveryFetch.get_documents_co;
}

export const createGetDocumentsCo = repository => (
  async (documentName, version) => {
    const url = await repository.getDocumentsCoUrl();
    return fetchJson(url, {
      method: 'POST',
      body: {
        'document-name': documentName,
        version,
      },
    });
  }
);

export const getDocumentsCo = createGetDocumentsCo({
  getDocumentsCoUrl,
});
