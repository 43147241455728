import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { useBreakpointsMediaDown } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import { css } from 'styled-components';
import { PropTypes } from '@nubank/nuds-web/index-0e40e0c2';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import { SiteContext } from 'components/SiteContext/SiteContext';

import { StyledImageExperiment } from '../../styles/Img';
import { StyledImage } from '../../styles/Image';
import { StyledButton } from '../../styles/StyledButton';
import { ImageContainer, QRCodeMultiProduct } from '../../styles/CustomStyles';

import { StyledLink } from './styles/Link';

const FeedbackProductApprovedExperiment = ({
  defaultProps,
  prospectNames,
}) => {
  const { formatMessage } = useIntl();
  const { setUsePurpleAsBackgroundInRegistrationForm, loadingTime } = useContext(SiteContext);
  useEffect(() => {
    sendEvent('REALTIME_ANALYSIS_APPROVED', { loadingTime: loadingTime.current });

    return () => setUsePurpleAsBackgroundInRegistrationForm(false);
  }, []);

  const isMobile = useBreakpointsMediaDown('md');
  const titleHtml = formatMessage({ id: defaultProps.title }).replace(
    '{prospectNames}',
    prospectNames,
  );

  return (
    <>
      <Box display="flex" justifyContent="left" css={ImageContainer}>
        <FormattedMessage id={defaultProps.imageAlt}>
          {msg => (
            <StyledImageExperiment
              src={requireImage(defaultProps.image)}
              alt={msg}
            />
          )}
        </FormattedMessage>
      </Box>
      <Typography
        color={defaultProps.titleColor}
        variant="heading2"
        data-testid="FeedbackProductApprovedTitle"
        gutterBottom={2}
        dangerouslySetInnerHTML={{ __html: titleHtml }}
      />
      <Typography
        css={css`strong { font-weight: bold; }`}
        color={defaultProps.subTitleColor1}
        variant="subtitle1"
        intlKey={defaultProps.subTitle1}
        gutterBottom={1}
        intlValues={{
          br: <br />,
          strong: msg => (
            <strong>{msg}</strong>
          ),
        }}
      />
      <Box>
        {defaultProps.steps.map((item, key) => (
          <Typography
            display="flex"
            flexDirection="column"
            marginVertical="8x"
            // key={`step_${key}`}
          >
            <Box
              justifyContent="center"
              display="flex"
              alignItems="center"
              borderRadius="50%"
              backgroundColor="#EFEFEF"
              width="36px"
              height="36px"
              marginRight="4x"
            >
              <Typography
                variant="caption"
                color="black"
              >
                {key + 1}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="subtitle2"
                css={css`strong { font-weight: bold; }`}
                intlKey={item.description}
                intlValues={{
                  strong: msg => (
                    <strong>{msg}</strong>
                  ),
                }}
              />
            </Box>
          </Typography>
        ))}
      </Box>
      {isMobile ? (
        <StyledLink
          href="https://nuapp.nubank.com.br/bnVhcHA6Ly9zaGVsbC9zcGxhc2hfc2NyZWVuLz91dG1fY2FtcGFpZ249cmVhbC10aW1lLXJlc3BvbnNlLWNvbG9tYmlh"
        >
          <StyledButton
            id="download-app-btn"
            intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.BUTTON.LABEL"
            styleVariant={defaultProps.buttonColor}
            variant="contained"
            extended
          />
        </StyledLink>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          css={QRCodeMultiProduct}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            maxWidth="50%"
          >
            <Icon
              name="arrow-right"
              color={defaultProps.iconColor}
            />
            <Typography
              variant="subtitle1"
              intlKey={defaultProps.footerLabel}
              color={defaultProps.footerLabelColor}
              paddingVertical="16px"
            />
          </Box>
          <StyledImage
            height="8rem"
            width="9rem"
            altIntlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.APPROVAL_SCREEN.QR.ALT"
            src="registration/openAppQrCode.jpg"
          />
        </Box>
      )}
    </>
  );
};

FeedbackProductApprovedExperiment.defaultProps = {
  prospectNames: '',
};

FeedbackProductApprovedExperiment.propTypes = {
  defaultProps: PropTypes.shape({
    buttonColor: PropTypes.string,
    footerLabel: PropTypes.string,
    footerLabelColor: PropTypes.string,
    iconColor: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
    })),
    subTitle1: PropTypes.string,
    subTitle2: PropTypes.string,
    subTitleColor1: PropTypes.string,
    subTitleColor2: PropTypes.string,
    title: PropTypes.string,
    titleColor: PropTypes.string,
  }).isRequired,
  prospectNames: PropTypes.string,
};

export default FeedbackProductApprovedExperiment;
