import styled from 'styled-components';

export const StyledImg = styled.img`
  height: 260px;
  margin-bottom: 1.5rem;
`;

StyledImg.displayName = 'StyledImg';

export const StyledImageExperiment = styled.img`
  width: 124px;
  height: 124px;
  margin-bottom: 1.5rem;
`;

StyledImageExperiment.displayName = 'StyledImg';
