import React, {
  useEffect, useRef, useContext,
} from 'react';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Form from '@nubank/nuds-web/components/Form/Form';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';
import RadioButtonGroup from '@nubank/nuds-web/components/RadioButtonGroup/RadioButtonGroup';
import PropTypes from 'prop-types';

import { SiteContext } from 'components/SiteContext/SiteContext';
import ColombiaFlag from 'components/ColombiaFlag/ColombiaFlag';
import { documentTypes } from 'utils/documentTypes';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { LegalDocumentLink } from '../../../../../components/GetLegalDocuments/GetLegalDocuments';
import { useGetDocumentsByRoute } from '../../../../../hooks/useGetDocuments';

import { StyledCheckbox } from './styles/Checkbox';
import { StyledLink } from './styles/Link';

const PersonalInfoOpenBankingStep = (
  {
    finalInitialValues,
  },
) => {
  const { formatMessage } = useIntl();
  const legalDocuments = useGetDocumentsByRoute();

  const {
    prospectEmail: email,
  } = useContext(SiteContext);

  const emailInputRef = useRef();

  useEffect(() => {
    sendEvent('PROSPECT_REGISTRATION_OPEN_BANKING_STARTED');
    emailInputRef.current?.focus();
    emailInputRef.current?.addEventListener('paste', event => event.preventDefault(), false);
  }, []);

  const getLinks = () => {
    const responseLinks = {};
    const links = [{
      name: 'privacyLink',
      path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.PATH',
      pathLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK.LABEL',
      strong: true,
      color: 'primary',
      variant: 'paragraph1',
    }, {
      name: 'privacyLink2',
      path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK2.PATH',
      pathLabel: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.LINK2.LABEL',
      strong: true,
      color: 'primary',
      variant: 'paragraph1',
    }];

    links?.forEach(link => {
      responseLinks[link.name] = (
        <LegalDocumentLink
          TypeLink={StyledLink}
          linkName={link.name}
          oldPath={formatMessage({ id: link.path })}
          documentsResponse={legalDocuments}
          target="_blank"
          rel="noopener"
          typographyProps={{
            variant: link.variant,
            strong: link.strong,
            color: link.color,
          }}
          intlKey={link.pathLabel}
        />
      );
    });

    return responseLinks;
  };

  const getCompanies = () => {
    const labels = {};
    const companies = [
      {
        name: 'SA',
        label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TERMS_CONDITIONS.LINK.LABEL',
        strong: true,
        variant: 'span',
      },
      {
        name: 'CF',
        label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TERMS_CONDITIONS.LINK2.LABEL',
        strong: true,
        variant: 'span',
      },
      {
        name: 'WhatsApp',
        label: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY',
        strong: false,
        variant: 'link',
        path: 'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE.COMPANY.URL',
        color: 'primary',
      },
    ];

    companies?.forEach(company => {
      if (company.variant === 'link') {
        labels[company.name] = (
          <LegalDocumentLink
            TypeLink={StyledLink}
            linkName={company.name}
            oldPath={formatMessage({ id: company.path })}
            documentsResponse={legalDocuments}
            target="_blank"
            rel="noopener"
            typographyProps={{
              strong: company.strong,
              color: company.color,
            }}
            intlKey={company.label}
          />
        );
      } else {
        labels[company.name] = (
          <Typography
            tag="span"
            intlKey={company.label}
            strong={company.strong}
          />
        );
      }
    });
    return labels;
  };

  const handleSubmit = async ({ nextStep }) => {
    sendEvent('OPEN_BANKING_CONTINUE_BUTTON');
    nextStep();
  };

  return (
    <Form.Step
      initialValues={{
        ...finalInitialValues,
        email,
      }}
      initialTouched={{ email }}
      onSubmit={handleSubmit}
    >
      {({
        isDisabled,
        formErrorMsg,
        isSubmitting,
        clearFormErrorMsg,
        currentStepValues: { documentType },
      }) => {
        const documentIdErrorMessage = documentType === 'CC'
          ? 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.CITIZENSHIP_CARD'
          : 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.VALIDATION_MESSAGE.FOREIGNER_ID';

        return (
          <>
            <Typography
              gutterBottom={1}
              id="personalInfoStepTitle"
              variant="heading4"
              intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.TITLE"
            />
            <Typography
              gutterBottom={2}
              variant="paragraph2"
              strong
              color="black"
              colorVariant="defaultT70"
              intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.MESSAGE"
            />
            <TextField
              id="email"
              name="email"
              data-testid="email"
              type="email"
              label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.LABEL' })}
              syncValidations={{
                required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE' }),
                email: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE' }),
              }}
            />

            <TextField
              id="confirmEmail"
              name="confirmEmail"
              type="email"
              data-testid="confirm-email"
              ref={emailInputRef}
              tabIndex="0"
              autoComplete="off"
              label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CONFIRM_EMAIL.LABEL' })}
              syncValidations={{
                required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE' }),
                email: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.VALIDATION_MESSAGE' }),
                equalsTo: { errorMsg: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.EMAIL.DIFFERENT_VALIDATION_MESSAGE' }), params: { target: 'email' } },
              }}
            />

            <TextField
              id="names"
              name="names"
              label={formatMessage({
                id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.LABEL',
              })}
              syncValidations={{
                required: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.VALIDATION_MESSAGE',
                }),
                names: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.NAMES.VALIDATION_MESSAGE',
                }),
              }}
            />

            <TextField
              id="firstSurname"
              name="firstSurname"
              label={formatMessage({
                id:
                    'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.LABEL',
              })}
              syncValidations={{
                required: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.VALIDATION_MESSAGE',
                }),
                firstSurname: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.VALIDATION_MESSAGE',
                }),
                nineteenMaxLength: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.FIRST_NAME.MAX_CHARS.VALIDATION_MESSAGE',
                }),
              }}
            />

            <TextField
              id="secondSurname"
              name="secondSurname"
              label={formatMessage({
                id:
                    'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.LABEL',
              })}
              syncValidations={{
                secondSurname: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.SECOND_SURNAME.VALIDATION_MESSAGE',
                }),
              }}
            />

            <RadioButtonGroup
              id="documentType"
              name="documentType"
              options={documentTypes}
              label={formatMessage({
                id:
                    'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.LABEL',
              })}
              syncValidations={{
                required: formatMessage({
                  id:
                      'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.DOCUMENT_TYPE.VALIDATION_MESSAGE',
                }),
              }}
            />

            <TextField
              id="idNumber"
              name="idNumber"
              label={formatMessage({
                id:
                    'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.ID_NUMBER.LABEL',
              })}
              syncValidations={{
                idNumber: formatMessage({ id: documentIdErrorMessage }),
                required: formatMessage({ id: documentIdErrorMessage }),
              }}
            />

            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                marginBottom="-1rem"
                variant="paragraph2"
                strong
                intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.LABEL"
                color="black.defaultT70"
              />
              <Box display="flex">
                <Box
                  display="flex"
                  whiteSpace="nowrap"
                  width="76px"
                  height="32px"
                  marginTop="1.25rem"
                  backgroundColor="white.dark"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius=".5rem"
                  marginRight="2x"
                  padding="1x"
                >
                  <ColombiaFlag />
                  <Typography
                    variant="paragraph1"
                    strong
                    color="black.defaultT70"
                  >
                    +57
                  </Typography>
                </Box>
                <Box width="100%">
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    label=""
                    placeholder={formatMessage({
                      id:
                          'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.LABEL',
                    })}
                    maxLength={10}
                    syncValidations={{
                      required: formatMessage({
                        id:
                            'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.VALIDATION_MESSAGE',
                      }),
                      phoneNumber: formatMessage({
                        id:
                            'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PHONE.VALIDATION_MESSAGE',
                      }),
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <StyledCheckbox
              id="policyAccepted"
              data-testid="policyAccepted"
              name="policyAccepted"
              syncValidations={{
                required: formatMessage({
                  id:
                      'MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.VALIDATION_MESSAGE',
                }),
              }}
              label={(
                <Typography
                  variant="paragraph1"
                  strong={false}
                  tag="span"
                  intlKey="MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY"
                  intlValues={getLinks()}
                />
                )}
            />

            <StyledCheckbox
              id="whatsappAccepted"
              data-testid="whatsappAccepted"
              name="whatsappAccepted"
              label={(
                <Typography
                  variant="paragraph1"
                  strong={false}
                  tag="span"
                  intlKey="MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.WHATSAPP_MESSAGE"
                  intlValues={getCompanies()}
                />
                )}
            />

            <Button
              id="personalinfo-step-submit-btn"
              intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CONTINUE_BUTTON"
              variant="contained"
              type="submit"
              size="default"
              extended
              iconProps={{ name: 'arrow-right', title: '' }}
              disabled={isDisabled || isSubmitting}
            />

            <Snackbar
              visible={Boolean(formErrorMsg)}
              onActionClick={clearFormErrorMsg}
              actionText={formatMessage({
                id:
                    'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.SNACKBAR_CLOSE',
              })}
            >
              {formErrorMsg}
            </Snackbar>
          </>
        );
      }}
    </Form.Step>
  );
};

PersonalInfoOpenBankingStep.propTypes = {
  finalInitialValues: PropTypes.shape({
    confirmEmail: PropTypes.string,
    documentType: PropTypes.string,
    firstSurname: PropTypes.string,
    idNumber: PropTypes.string,
    names: PropTypes.string,
    phoneNumber: PropTypes.string,
    policyAccepted: PropTypes.bool,
    secondSurname: PropTypes.string,
    whatsappAccepted: PropTypes.bool,
  }).isRequired,
};

export default PersonalInfoOpenBankingStep;
