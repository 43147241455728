import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import { SiteContext } from 'components/SiteContext/SiteContext';

import { ImageContainer } from '../DecisionStep/styles/CustomStyles';
import { StyledImg } from '../DecisionStep/styles/Img';

const CongratsStep = (
  {
    prospectEmail,
    prospectNames,
  },
) => {
  const { closeRegistrationForm } = useContext(SiteContext);

  return (
    <>
      <Box display="flex" justifyContent="center" css={ImageContainer}>
        <FormattedMessage id="PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT">
          {msg => (
            <StyledImg
              src={requireImage('registration/openBankingConfirmation.svg')}
              alt={msg}
            />
          )}
        </FormattedMessage>
      </Box>
      <Typography
        gutterBottom={1}
        id="congratsStepTitle"
        variant="heading3"
        intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP.TITLE"
        intlValues={{
          name: (
            <Typography
              color="primary"
              strong
              variant="heading3"
              tag="span"
              colorVariant="default"
            >
              {prospectNames}
            </Typography>
          ),
        }}
      />

      <Typography
        gutterBottom={2}
        variant="subtitle1"
        color="black.light"
        strong
        colorVariant="defaultT70"
        intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP.MESSAGE"
        intlValues={{
          br: <br />,
          email: (
            <Typography
              color="primary"
              strong
              variant="subtitle1"
              tag="span"
              colorVariant="default"
            >
              {prospectEmail}
            </Typography>
          ),
        }}
      />
      <Typography
        gutterBottom={2}
        variant="subtitle1"
        color="black"
        strong
        colorVariant="defaultT70"
        intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP.MESSAGE_2"
      />
      <Typography
        gutterBottom={2}
        variant="subtitle1"
        color="black"
        colorVariant="defaultT70"
        intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP.MESSAGE_3"
        intlValues={{
          help: (
            <Typography
              color="black.light"
              strong
              variant="subtitle1"
              tag="span"
              colorVariant="default"
              intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP.HELP"
            />
          ),
        }}
      />
      <Box marginTop="auto" />
      <Button
        id="openbanking-step-entendido-btn"
        intlKey="PROSPECT_REGISTRATION_FORM.CONGRATS_STEP_STEP.AGREE_BUTTON"
        type="submit"
        variant="contained"
        onClick={closeRegistrationForm}
        extended
      />
    </>
  );
};

CongratsStep.defaultProps = {};

CongratsStep.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
  prospectNames: PropTypes.string.isRequired,
};

export default CongratsStep;
