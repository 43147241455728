import styled from 'styled-components';
import { spacing } from '@nubank/nuds-web/styles/themeUtils';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const StyledCallout = styled.div`
background-color: #EFEFEF;
border-radius: 16px;
margin-bottom: ${spacing('6x')};
padding: ${spacing('4x')};
`;

StyledCallout.displayName = 'StyledCallout';

export const StyledCollapse = styled(Collapse)`
  border-top: 0;

  button {
    margin-bottom: ${spacing('2x')};
  };
`;

StyledCollapse.displayName = 'StyledCollapse';

export const CollapseContent = styled.div`
display: flex;
flex-direction: column;
row-gap: ${spacing('2x')};
margin: ${spacing('2x')} 0;
padding-bottom: ${spacing('2x')};
`;

CollapseContent.displayName = 'CollapseContent';

export const InnerCallout = styled.div`
background-color: #E1E1E1;
border-radius: 16px;
padding: ${spacing('4x')};
`;

InnerCallout.displayName = 'InnerCallout';

export const Styledline = styled.hr`
width: 100%;
height: 1px;
background-color: #E1E1E1;
`;

Styledline.displayName = 'Styledline';

export const StyledDescription = styled(Typography)`
  padding-right: ${spacing('4x')};
`;

StyledDescription.displayName = 'StyledDescription';
