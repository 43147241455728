import React, {
  useContext, useEffect, useRef,
  useState,
} from 'react';
import { useBreakpointsMediaDown, useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import { MenuContext } from './MenuContext';
import Actions from './patterns/Actions/Actions';
import ActionsSmallScreen from './patterns/ActionsSmallScreen/ActionsSmallScreen';
import ButtonToggle from './patterns/ButtonToggle/ButtonToggle';
import Logo from './patterns/Logo/Logo';
import Navigation, { NavigationKeyToComponentMap } from './patterns/Navigation/Navigation';
import NavigationListSmallScreen from './patterns/Navigation/NavigationListSmallScreen/NavigationListSmallScreen';
import {
  getChildrenByType,
  normalizeNavigationStructure,
  normalizeNavigationStructureComponents,
  typeValidation,
} from './utils';
import {
  MenuArea, LogoMenuWrapper, LogoArea, ButtonToggleArea,
} from './styles/CucMenuStyles';

const NavigationListLargeScreen = dynamic(
  () => import('./patterns/Navigation/NavigationListLargeScreen/NavigationListLargeScreen'),
  {
    loading: () => <Box width="100%" height="100%" backgroundColor="white.default" />,
    ssr: false,
  },
);

function CucMenu({
  children,
  className,
  mobileButtonIconTitle,
  onToggleMobileMenu,
  hideMobileMenu,
  currentRoutePath: routePath,
}) {
  const logoChildren = getChildrenByType(children, ['Logo']);
  const actionsChildren = getChildrenByType(children, ['Actions']);
  const actionsSmallScreenChildren = getChildrenByType(children, ['ActionsSmallScreen']);
  const [navigationChildren] = getChildrenByType(children, ['Navigation']);
  const navigationComponentStructure = navigationChildren
    ? normalizeNavigationStructureComponents(navigationChildren
      .props
      .children(NavigationKeyToComponentMap)
      .props
      .children)
    : [];

  const { isMobileNavOpen: initialIsMobileNavOpen } = useContext(MenuContext);
  const menuWrapperRef = useRef(null);
  const menuNavigationStructure = normalizeNavigationStructure(navigationComponentStructure);
  const [navigationSectionActive, setNavigationSectionActive] = useState('');
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(initialIsMobileNavOpen);
  const [currentRoutePath, currentsetRoutePath] = useState(routePath);
  const [isServer] = useState(typeof window === 'undefined');

  const toggleMobileMenu = () => {
    setIsMobileNavOpen(prevValue => {
      onToggleMobileMenu(!prevValue);
      return !prevValue;
    });
  };

  useEffect(() => {
    if (routePath) {
      currentsetRoutePath(routePath);
    }
  }, [routePath]);

  const breakpointsDownLG = isServer ? true : useBreakpointsMediaDown('lg');
  const breakpointsUpLG = isServer ? false : useBreakpointsMediaUp('lg');

  return (
    <MenuContext.Provider
      value={{
        navigationSectionActive,
        setNavigationSectionActive,
        menuNavigationStructure,
        isMobileNavOpen,
        setIsMobileNavOpen,
        currentRoutePath,
        currentsetRoutePath,
        toggleMobileMenu,
      }}
    >
      {navigationChildren}
      <MenuArea
        ref={menuWrapperRef}
        className={className}
      >
        <LogoMenuWrapper>
          <LogoArea>
            {logoChildren}
          </LogoArea>

          {breakpointsUpLG && (
            <NavigationListLargeScreen componentsList={navigationComponentStructure} />
          )}

          <Box>{actionsChildren}</Box>

          {(!hideMobileMenu && breakpointsDownLG) && (
            <ButtonToggleArea>
              <ButtonToggle
                onMenuToggleClick={toggleMobileMenu}
                isMobileNavOpen={isMobileNavOpen}
                iconProps={mobileButtonIconTitle}
              />
            </ButtonToggleArea>
          )}
        </LogoMenuWrapper>

        {breakpointsDownLG && (
          <NavigationListSmallScreen
            listNavigationComponents={navigationComponentStructure}
            actionsSmallScreenChildren={actionsSmallScreenChildren}
            isHidden={!isMobileNavOpen}
          />
        )}
      </MenuArea>
    </MenuContext.Provider>
  );
}

CucMenu.Logo = Logo;
CucMenu.Actions = Actions;
CucMenu.ActionsSmallScreen = ActionsSmallScreen;
CucMenu.Navigation = Navigation;

CucMenu.defaultProps = {
  className: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  __TYPE: 'Menu',
  hideMobileMenu: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  onToggleMobileMenu: () => { },
  currentRoutePath: undefined,
};

CucMenu.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Menu'),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  currentRoutePath: PropTypes.string,
  hideMobileMenu: PropTypes.bool,
  mobileButtonIconTitle: PropTypes.shape({
    titleClose: PropTypes.string,
    titleOpen: PropTypes.string,
  }).isRequired,
  onToggleMobileMenu: PropTypes.func,
};

export default CucMenu;
