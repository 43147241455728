import styled, { css } from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledButton = styled(Button)`
    bottom: 0;
    margin-bottom: 1.5rem;
    position: fixed;
    width: calc(100% - 3rem);
    
    ${breakpointsMedia({
    lg: css`
      width: calc(100% - 5rem);
    `,
  })}
`;

StyledButton.displayName = 'StyledButton';
