import React, { useContext } from 'react';
import Button from '@nubank/nuds-web/components/Button/Button';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { SiteContext } from '../../../../../components/SiteContext/SiteContext';

import { HeaderContainer } from './styles/HeaderContainer';
import {
  HeaderWrapper, ButtonWrapper, TitleWrapper, Styledline,
} from './styles/ClarityHeaderContainer';

const FormHeader = ({ displayClaritytHeader, isClarityExperimentOn }) => {
  const {
    closeRegistrationForm,
    usePurpleAsBackgroundInRegistrationForm,
  } = useContext(SiteContext);
  const { formatMessage } = useIntl();

  const ClarityExperimentHeader = () => (
    <HeaderWrapper>
      <ButtonWrapper>
        <Button
          data-testid="close-btn"
          variant="basic"
          styleVariant="black"
          onClick={closeRegistrationForm}
          iconProps={{
            name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
          }}
        />
      </ButtonWrapper>
      <TitleWrapper>
        <Typography
          gutterBottom={1}
          id="personalInfoStepClarityTitle"
          variant="heading4"
          intlKey="MULTI_PRODUCT_PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CLARITY.TITLE"
          intlValues={{
            span: msg => (
              <span>{msg}</span>
            ),
          }}
        />
      </TitleWrapper>
      <Styledline />
    </HeaderWrapper>
  );

  const DefaultHeader = () => (
    <HeaderContainer>
      <Button
        data-testid="close-btn"
        variant="basic"
        styleVariant={usePurpleAsBackgroundInRegistrationForm ? 'white' : 'primary'}
        onClick={closeRegistrationForm}
        iconProps={{
          name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
        }}
      />
    </HeaderContainer>
  );

  return (
    isClarityExperimentOn && displayClaritytHeader ? <ClarityExperimentHeader /> : <DefaultHeader />
  );
};

FormHeader.defaultProps = {
  displayClaritytHeader: false,
  isClarityExperimentOn: false,
};

FormHeader.propTypes = {
  displayClaritytHeader: PropTypes.bool,
  isClarityExperimentOn: PropTypes.bool,
};

export default FormHeader;
