import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import { SiteContext } from 'components/SiteContext/SiteContext';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { StyledImg } from '../../styles/Img';
import { StyledButton } from '../../styles/StyledButton';
import { Subtitle, ImageContainer } from '../../styles/CustomStyles';

const FeedbackProductDenied = ({
  prospectEmail,
}) => {
  const { closeRegistrationForm, loadingTime } = useContext(SiteContext);

  useEffect(() => {
    sendEvent('REALTIME_ANALYSIS_DENIED', { loadingTime: loadingTime.current });
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="center" css={ImageContainer}>
        <FormattedMessage id="PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT">
          { msg => (
            <StyledImg
              src={requireImage('registration/merchant.svg')}
              alt={msg}
            />
          )}
        </FormattedMessage>
      </Box>
      <Typography
        variant="heading3"
        data-testid="FeedbackProductDeniedTitle"
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.DENIAL_SCREEN.TITLE"
        gutterBottom={1}
      />
      <Typography
        variant="subtitle1"
        strong
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.DENIAL_SCREEN.SUBTITLE"
        gutterBottom={4}
        css={Subtitle}
        intlValues={{
          br: <br />,
          email: (
            <Typography
              color="primary"
              strong
              variant="subtitle1"
              tag="span"
              colorVariant="default"
            >
              {prospectEmail}
            </Typography>
          ),
          noBureaucracy: (
            <Typography
              color="primary"
              strong
              intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.DENIAL_SCREEN.SUBTITLE.NO_BUREAUCRACY"
              variant="subtitle1"
              tag="span"
              colorVariant="default"
              intlValues={{
                br: <br />,
              }}
            />
          ),
        }}
      />
      <StyledButton
        id="decision-denied-step-close-btn"
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.BUTTON.LABEL"
        type="submit"
        variant="contained"
        onClick={closeRegistrationForm}
        extended
      />
    </>
  );
};

FeedbackProductDenied.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
};

export default FeedbackProductDenied;
