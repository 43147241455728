const cleanProspectData = prospectData => Object.keys(prospectData).reduce((acc, key) => ({
  ...acc,
  [key]: prospectData[key].trim(),
}), {});

export const createProspectFromForm = ({
  names,
  firstSurname,
  secondSurname,
  documentType,
  idNumber,
  email,
  phoneNumber,
}) => {
  const phoneObject = phoneNumber ? { phone: phoneNumber && `57${phoneNumber}` } : {};
  const prospectData = {
    'given-names': names,
    'first-surname': firstSurname,
    'second-surname': secondSurname,
    cpf: `${documentType}-${idNumber}`,
    email,
    'government-id-type': documentType,
    ...phoneObject,
  };
  return cleanProspectData(prospectData);
};

export const createSavingsWaitlistRegisterFromForm = ({
  names,
  firstSurname,
  secondSurname,
  documentType,
  idNumber,
  email,
  phoneNumber,
}) => {
  const phoneObject = phoneNumber ? { phone: phoneNumber && `57${phoneNumber}` } : {};
  const data = {
    name: names,
    'first-surname': firstSurname,
    'second-surname': secondSurname,
    'tax-id': `${documentType}-${idNumber}`,
    email,
    ...phoneObject,
  };

  return cleanProspectData(data);
};

