import { isProduction } from '@nubank/www-latam-commons/utils/environmentUtils';
import base64Decode from '@nubank/www-latam-commons/utils/base64/decode';

export const getConfig = () => ({
  interestRateFileUrlEncodedStaging: process.env.NEXT_PUBLIC_WWW_INTEREST_RATE_URL_ENCODED_STAGING,
  interestRateFileUrlEncodedProd: process.env.NEXT_PUBLIC_WWW_INTEREST_RATE_URL_ENCODED_PROD,
  psePaymentsCloseOnVisibilityHiddenStaging:
    process.env.NEXT_PUBLIC_PSE_PAYMENTS_CLOSE_ON_VISIBILITY_HIDDEN_STAGING,
  psePaymentsCloseOnVisibilityHiddenProd:
    process.env.NEXT_PUBLIC_PSE_PAYMENTS_CLOSE_ON_VISIBILITY_HIDDEN_PROD,
  hidePPTStaging:
    process.env.NEXT_PUBLIC_HIDE_PPT_STAGING,
  hidePPTProd:
    process.env.NEXT_PUBLIC_HIDE_PPT_PROD,
  enableCUCUTAStaging:
    process.env.NEXT_PUBLIC_ENABLE_CUCUTA_STAGING,
  enableCUCUTAProd:
    process.env.NEXT_PUBLIC_ENABLE_CUCUTA_PROD,
});

const config = getConfig();

export function getConfigValue(prodStrKey, stagingStrKey) {
  return isProduction() ? config[prodStrKey] : config[stagingStrKey];
}

export function getDecodedConfigStrFunction(prodStrKey, stagingStrKey) {
  return () => {
    const str64 = getConfigValue(prodStrKey, stagingStrKey);
    return base64Decode(str64);
  };
}

export const getInterestRateFileUrl = getDecodedConfigStrFunction('interestRateFileUrlEncodedProd', 'interestRateFileUrlEncodedStaging');

export const getPsePaymentsCloseOnVisibilityHidden = getConfigValue(
  'psePaymentsCloseOnVisibilityHiddenProd',
  'psePaymentsCloseOnVisibilityHiddenStaging',
);

export const getHidePPT = getConfigValue(
  'hidePPTStaging',
  'hidePPTProd',
);

export const getEnableCucuta = getConfigValue('enableCUCUTAProd', 'enableCUCUTAStaging');
