import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';

import { SiteContext } from '../SiteContext/SiteContext';

const HeaderMenuButtons = () => {
  const { toggleMenu, isStickyHeaderActive } = useContext(SiteContext);
  const { formatMessage } = useIntl();

  return (
    <>
      <Box display={{ xs: 'block', md: 'none' }}>
        <Button
          variant="basic"
          iconProps={{ name: 'menu', title: formatMessage({ id: 'HEADER.MENU.BUTTON.TITLE' }) }}
          styleVariant={isStickyHeaderActive ? 'primary' : 'black'}
          onClick={toggleMenu}
        />
      </Box>

      <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
        <Button
          intlKey="HEADER.MENU.BUTTON.TITLE"
          variant="basic"
          styleVariant={isStickyHeaderActive ? 'primary' : 'black'}
          iconProps={{ name: 'menu', title: formatMessage({ id: 'HEADER.MENU.BUTTON.TITLE' }) }}
          onClick={toggleMenu}
        />
      </Box>
    </>
  );
};

export default HeaderMenuButtons;
