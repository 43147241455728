import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Link from '@nubank/nuds-web/components/Link/Link';

const FooterSocial = ({ socials }) => {
  const { formatMessage } = useIntl();

  return (
    <Box padding="0rem 0rem 3rem">
      <Grid>
        <Grid.Child
          gridColumn={{
            xs: '3 / span 4',
            md: '9 / span 3',
            lg: '22 / span 3',
          }}
        >
          {socials.map(({
            name, link,
          }) => (
            <Link
              key={link}
              href={formatMessage({ id: link })}
              color="black"
              iconProps={{ name: formatMessage({ id: name }) }}
              paddingLeft="6x"
            />
          ))}
        </Grid.Child>
      </Grid>
    </Box>
  );
};

FooterSocial.propTypes = {
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FooterSocial;
