import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { INCREASE_CLARITY } from '../../utils/experiments';

import { CTAButton, CTAButtonWrapper } from './styles/CTAButton';
import useCTAVisibility from './useCTAVisibility';

const HiddeableHeaderCTA = ({
  hiddenHeight,
  btnLabel,
  styleVariant,
  buttonColor,
  hasBlur,
  btnLabelClarity,
}) => {
  const isVisible = useCTAVisibility(hiddenHeight);
  const activeVariant = useFigPiiExperiment(INCREASE_CLARITY.id);
  const isClarityExperimentOn = activeVariant === INCREASE_CLARITY.treatment;

  const handleClick = () => {
    document.getElementById('email-on-hero').focus();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (isVisible && (
  <CTAButtonWrapper hasBlur={hasBlur}>
    <CTAButton
      extended
      variant="contained"
      css={css`
            background: ${isClarityExperimentOn ? 'black' : buttonColor};
          `}
      styleVariant={styleVariant}
      onClick={handleClick}
      intlKey={isClarityExperimentOn ? btnLabelClarity : btnLabel}
    />
  </CTAButtonWrapper>
  )
  );
};

HiddeableHeaderCTA.propTypes = {
  btnLabel: PropTypes.string,
  btnLabelClarity: PropTypes.string,
  buttonColor: PropTypes.string,
  hasBlur: PropTypes.bool,
  hiddenHeight: PropTypes.shape({
    lg: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired,
    xl: PropTypes.number.isRequired,
    xs: PropTypes.number.isRequired,
  }).isRequired,
  styleVariant: PropTypes.string,
};

HiddeableHeaderCTA.defaultProps = {
  btnLabelClarity: 'MULTI_PRODUCT.INCREASE_CLARITY_EXPERIMENT.SIGNUP_FORM.BUTTON_LABEL',
  btnLabel: 'HEADER.MENU.CTA_BUTTON.LABEL',
  styleVariant: 'primary',
  buttonColor: 'primary',
  hasBlur: false,
};

export default HiddeableHeaderCTA;
