import React from 'react';
import PropTypes from 'prop-types';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { INCREASE_CLARITY } from '../../utils/experiments';

import { CTAButton } from './styles/CTAButton';

const HeaderCTA = ({ btnLabel, href, btnLabelClarity }) => {
  const handleClick = () => {
    window.location = href;
  };
  const activeVariant = useFigPiiExperiment(INCREASE_CLARITY.id);
  const isClarityExperimentOn = activeVariant === INCREASE_CLARITY.treatment;

  return (
    <CTAButton
      extended
      variant="contained"
      color={isClarityExperimentOn ? 'black' : 'primary'}
      onClick={handleClick}
      intlKey={isClarityExperimentOn ? btnLabelClarity : btnLabel}
    />
  );
};

HeaderCTA.propTypes = {
  btnLabel: PropTypes.string,
  btnLabelClarity: PropTypes.string,
  href: PropTypes.string,
};

HeaderCTA.defaultProps = {
  btnLabel: 'HEADER.MENU.CTA_BUTTON.LABEL',
  btnLabelClarity: 'MULTI_PRODUCT.INCREASE_CLARITY_EXPERIMENT.SIGNUP_FORM.BUTTON_LABEL',
  href: '/',
};

export default HeaderCTA;
