import styled, { css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { nuDSColor, spacing } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const MenuArea = styled(Box)`
  width: 100%;
  position: relative;

  ${breakpointsMedia({
    xs: css`
      box-shadow: 0 1px 1px ${nuDSColor('black', 'defaultT10')()};
    `,
    lg: css`
      box-shadow: none;
      `,
  })};
`;

export const LogoMenuWrapper = styled(Box)`
  display: grid;
  align-items: stretch;
  background-color: ${nuDSColor('white', 'default')};
  grid-template-columns:min-content minmax(160px,1fr) minmax(min-content, auto);
  position: relative;
  width: 100%;

  ${breakpointsMedia({
    xs: css`
      height: 64px;
      padding-left: ${spacing('6x')};
      padding-right: 10px;
      z-index: 2;
      grid-gap: ${spacing('2x')};
    `,
    md: css`
      padding-left: ${spacing('8x')};
      padding-right: 18px;
      `,
    lg: css`
      height: 56px;
      padding-left: ${spacing('12x')};
      padding-right: ${spacing('12x')};
      z-index: auto;
      grid-gap: ${spacing('8x')};
      `,
  })};
`;

export const LogoArea = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
`;

export const ButtonToggleArea = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

MenuArea.displayName = 'MenuArea';
LogoMenuWrapper.displayName = 'LogoMenuWrapper';
LogoArea.displayName = 'LogoArea';
ButtonToggleArea.displayName = 'ButtonToggleArea';
