import { useState, useEffect } from 'react';
import { useBreakpointMedia } from '@nubank/nuds-web/styles/breakpoints';

const useCTAVisibility = hiddenHeight => {
  const [isVisible, setIsVisible] = useState(false);

  const breakpoint = useBreakpointMedia();

  const handleScrollPosition = () => {
    const yPosition = window.scrollY;
    const heroHeight = hiddenHeight[breakpoint];
    const isVisibleRange = yPosition > heroHeight;
    setIsVisible(isVisibleRange);
  };

  useEffect(() => {
    handleScrollPosition();
    window.addEventListener('scroll', handleScrollPosition, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, []);

  return isVisible;
};

export default useCTAVisibility;
