import React from 'react';
import PropTypes from 'prop-types';

const PigIcon = ({ className, title }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {title}
    <path d="M11.3333 6.33302H9.99996V7.66635H11.3333V6.33302Z" fill="white" />
    <path d="M2.21738 2.13754C2.22084 2.24301 2.24928 2.34616 2.30037 2.43849C2.35146 2.53083 2.42375 2.60972 2.51127 2.66867C2.5842 2.71778 2.66597 2.75189 2.75182 2.76922C3.65129 2.07757 4.7776 1.66635 5.99996 1.66635H13.3333V3.99969L12.4705 4.5389L13.271 5.73959C13.5181 6.11027 13.9338 6.33271 14.3793 6.33302H15.3333V9.66635C14.7946 9.98959 14.3255 10.3477 13.8698 10.6955C12.9157 11.4238 12.0201 12.1074 10.6666 12.333V13.6664H9.33329V12.333H4.66663V13.6664H3.33329V12.333C0.999959 10.333 0.666626 8.97377 0.666626 6.99969C0.666626 5.7848 1.07284 4.66478 1.75684 3.76806C1.49857 3.59192 1.28514 3.35766 1.13373 3.08403C0.980452 2.80702 0.895124 2.49758 0.884764 2.18117C0.874405 1.86475 0.939311 1.55039 1.07414 1.26395C1.20897 0.977508 1.40988 0.727167 1.66033 0.533516C1.91078 0.339865 2.20363 0.208436 2.51479 0.15004C2.82594 0.0916446 3.14651 0.10795 3.45014 0.197614C3.75376 0.287279 4.03177 0.447742 4.26128 0.665806L3.34289 1.63242C3.26638 1.55973 3.17372 1.50624 3.07251 1.47635C2.9713 1.44646 2.86444 1.44103 2.76072 1.4605C2.65701 1.47996 2.55939 1.52377 2.4759 1.58832C2.39242 1.65287 2.32545 1.73632 2.28051 1.8318C2.23557 1.92728 2.21393 2.03206 2.21738 2.13754ZM12 3.26069V2.99969H5.99996C3.79082 2.99969 1.99996 4.79055 1.99996 6.99969C1.99996 7.91551 2.08011 8.52781 2.34165 9.11125C2.57536 9.63263 2.99967 10.2297 3.83866 10.9997H10.5484C11.481 10.8199 12.1245 10.3504 13.0607 9.63569L13.0809 9.62031C13.3576 9.40905 13.6632 9.17574 14 8.94246V7.63915C13.2564 7.53214 12.5852 7.1146 12.1616 6.47919L10.5995 4.13599L12 3.26069Z" fill="white" />
  </svg>
);

PigIcon.defaultProps = {
  className: undefined,
  title: 'Credit Card Icon',
};

PigIcon.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default PigIcon;
