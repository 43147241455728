import { createHash } from 'crypto';

import React, {
  useCallback, useContext, useState,
} from 'react';
import { useIntl } from 'react-intl';
import Form from '@nubank/nuds-web/components/Form/Form';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';

import { SiteContext } from 'components/SiteContext/SiteContext';
import { registerProspect } from 'domains/prospect/registerProspect';
import { CREDIT_CARD, getProspectType } from '@nubank/www-latam-commons/utils/prospectTypes';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { LegalDocumentLink } from '../../../../../components/GetLegalDocuments/GetLegalDocuments';
import { useGetDocumentsByRoute } from '../../../../../hooks/useGetDocuments';

const DECISION_STEP = 4;
const CONGRAT_STEP = 2;
const LOAD_STEP = 3;
const BELVO_URL = 'https://nu.belvo-link.com/?external_id=';

const openBelvoTab = values => {
  const taxID = `${values.documentType}-${values.idNumber}`;
  const hash = createHash('sha256').update(taxID).digest('hex');

  const newWindow = window.open(BELVO_URL + hash, '_blank');
  if (newWindow) newWindow.opener = null;
};

const handleProspectRegistration = (obDecisitonType,
  documentsResponse, isLHFActive, startRealtimeAnalysis,
  finishRealtimeAnalysis, setRealtimeResult, formatMessage) => async ({
  goToStep, setSubmitting, setFormErrorMsg, values,
}) => {
  const prospectType = getProspectType(CREDIT_CARD);
  const isOBActive = true;

  sendEvent(obDecisitonType);

  if (obDecisitonType === 'DECLINE_OPEN_BANKING') {
    try {
      await registerProspect({
        formValues: values,
        prospectType,
        isLHFActive,
        isOBActive,
        OBDecisionType: obDecisitonType,
        legalDocuments: documentsResponse,
        onApplicationFinish: () => {
          setSubmitting(false);
          goToStep(DECISION_STEP);
        },
        onAnalysisStart() {
          startRealtimeAnalysis();
          setSubmitting(false);
          goToStep(LOAD_STEP);
        },
        onAnalysisFinish(analysisResult) {
          finishRealtimeAnalysis();
          setRealtimeResult(analysisResult);
          goToStep(DECISION_STEP);
        },
      });
    } catch (e) {
      setFormErrorMsg(
        formatMessage({
          id:
              'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.GENERIC.ERROR_MESSAGE',
        }),
      );
    } finally {
      setSubmitting(false);
    }
  } else {
    openBelvoTab(values);

    try {
      await registerProspect({
        formValues: values,
        prospectType,
        isLHFActive,
        isOBActive,
        OBDecisionType: obDecisitonType,
        legalDocuments: documentsResponse,
        onApplicationFinish: () => {
          setSubmitting(false);
        },
        onAnalysisStart() {
          startRealtimeAnalysis();
          setSubmitting(false);
          goToStep(CONGRAT_STEP);
        },
        onAnalysisFinish(analysisResult) {
          finishRealtimeAnalysis();
          setRealtimeResult(analysisResult);
        },
      });
    } catch (e) {
      setFormErrorMsg(
        formatMessage({
          id:
              'PROSPECT_REGISTRATION_FORM.AUTHORIZATION_STEP.GENERIC.ERROR_MESSAGE',
        }),
      );
    } finally {
      setSubmitting(false);
    }
  }
};

const OpenBankingStep = (
  {
    isLHFActive,
    finalInitialValues,
    setRealtimeResult,
  },
) => {
  const { formatMessage } = useIntl();
  const legalDocuments = useGetDocumentsByRoute();

  const [openBankingDecitionType, setOpenBankingDecitionType] = useState('');

  const {
    prospectEmail: email,
    startRealtimeAnalysis,
    finishRealtimeAnalysis,
  } = useContext(SiteContext);

  const submitEvent = useCallback(handleProspectRegistration(openBankingDecitionType,
    legalDocuments, isLHFActive, startRealtimeAnalysis, finishRealtimeAnalysis,
    setRealtimeResult, formatMessage), [openBankingDecitionType]);

  return (
    <Form.Step
      initialValues={{
        ...finalInitialValues,
        email,
      }}
      initialTouched={{ email }}
      onSubmit={submitEvent}
    >
      {({
        isSubmitting,
      }) => (
        <>
          <Typography
            gutterBottom={1}
            id="openBankingStepTitle"
            variant="heading4"
            intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.TITLE"
          />
          <Typography
            gutterBottom={2}
            variant="subtitle1"
            color="black"
            strong
            intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.MESSAGE"
          />
          <Typography
            gutterBottom={2}
            variant="subtitle1"
            color="black"
            intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.MESSAGE_2"
            intlValues={{
              br: <br />,
              policyLink: (
                <LegalDocumentLink
                  documentName="nu_SA_authorization_policy"
                  oldPath={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.PRIVACY_POLICY.PATH' })}
                  documentsResponse={legalDocuments}
                  style={{
                    textDecoration: 'underline',
                  }}
                  color="black"
                  target="_blank"
                  rel="noopener"
                  typographyProps={{
                    variant: 'subtitle1',
                    strong: true,
                  }}
                  intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.MESSAGE_2.POLICY.LINK.LABEL"
                />
              ),
            }}
          />

          <Box marginTop="auto" minHeight="100%" />
          <Button
            id="openbanking-step-continue-btn"
            intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.CONTINUE_BUTTON"
            variant="contained"
            onClick={() => setOpenBankingDecitionType('ACCEPT_OPEN_BANKING')}
            type="submit"
            extended
            disabled={isSubmitting}
            style={{ justifyContent: 'center' }}
            iconProps={{ name: 'arrow-up-right', title: '' }}
          />

          <Button
            id="openbanking-step-cancel-btn"
            intlKey="PROSPECT_REGISTRATION_FORM.OPEN_BANKING_STEP.CANCEL_BUTTON"
            variant="basic"
            size="default"
            icon
            styleVariant="primary"
            onClick={() => setOpenBankingDecitionType('DECLINE_OPEN_BANKING')}
            type="submit"
            disabled={isSubmitting}
            extended
          />
        </>
      )}
    </Form.Step>
  );
};

OpenBankingStep.defaultProps = {
  isLHFActive: false,
  setRealtimeResult: () => {
  },
};

OpenBankingStep.propTypes = {
  finalInitialValues: PropTypes.shape({
    confirmEmail: PropTypes.string,
    documentType: PropTypes.string,
    firstSurname: PropTypes.string,
    idNumber: PropTypes.string,
    names: PropTypes.string,
    phoneNumber: PropTypes.string,
    policyAccepted: PropTypes.bool,
    secondSurname: PropTypes.string,
    whatsappAccepted: PropTypes.bool,
  }).isRequired,
  isLHFActive: PropTypes.bool,
  setRealtimeResult: PropTypes.func,
};

export default OpenBankingStep;
