import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Button from '@nubank/nuds-web/components/Button/Button';

export const CTAButton = styled(Button)`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  ${breakpointsMedia({
    xs: css`
      margin-top: 0.4rem;
      width: 88%;
    `,
    md: css`
      margin-top: 0.3rem;
    `,
  })}
`;

export const CTAButtonWrapper = styled.div`
  height: 60px;
  ${breakpointsMedia({
    xs: css`
      backdrop-filter: ${props => (props.hasBlur ? 'blur(8px)' : 'none')};
      background: ${props => (props.hasBlur ? 'rgba(255 255 255 / 48%)' : 'none')};
      position: fixed;
      bottom: ${props => (props.hasBlur ? '48px' : '0')};
      left: 0;
      width: 100%;
      `,
    md: css`
      background: none;
      backdrop-filter: none;
      position: initial;
      width: auto; 
      `,
  })}

`;

CTAButton.displayName = 'CTAButton';
CTAButtonWrapper.displayName = 'CTAButtonWrapper';
