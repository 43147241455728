import React from 'react';
import PropTypes from 'prop-types';
import Link from '@nubank/nuds-web/components/Link/Link';

import { useGetDocumentsByRoute } from '../../hooks/useGetDocuments';

export const getPathDocumentByKey = (documentName, oldPath, documentsResponse) => {
  let dataPolicydocument = documentsResponse?.find(({ name }) => name === documentName);
  if (dataPolicydocument === undefined && documentName === 'nu_CF_data_policy') {
    dataPolicydocument = documentsResponse?.find(({ name }) => name === 'nu_SA_data_policy');
  }
  return dataPolicydocument?.url ?? oldPath;
};

const documentsByLink = [{ link: 'privacyLink', name: 'nu_SA_authorization_policy' },
  { link: 'privacyLink2', name: 'nu_CF_authorization_policy' },
  { link: 'WhatsApp', name: 'whatsapp_policy' }];

export const getPathDocumentByLink = (linkName, path, documentsResponse) => {
  const documentName = documentsByLink.find(({ link }) => link === linkName)?.name;
  const document = documentsResponse?.find(({ name }) => name === documentName);

  return document?.url ?? path;
};

export const LegalDocumentLink = ({
  TypeLink,
  documentName,
  linkName,
  oldPath,
  documentsResponse,
  ...props
}) => {
  const documents = documentsResponse ?? useGetDocumentsByRoute();
  const url = documentName
    ? getPathDocumentByKey(documentName, oldPath, documents)
    : getPathDocumentByLink(linkName, oldPath, documents);

  return (
    <TypeLink
      href={url}
      {...props}
    />
  );
};

LegalDocumentLink.defaultProps = {
  TypeLink: Link,
  documentName: undefined,
  linkName: undefined,
  documentsResponse: undefined,
};

LegalDocumentLink.propTypes = {
  TypeLink: PropTypes.elementType,
  documentName: PropTypes.node,
  documentsResponse: PropTypes.arrayOf(PropTypes.shape),
  linkName: PropTypes.node,
  oldPath: PropTypes.node.isRequired,
};
