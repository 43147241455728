import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
`;

HeaderContainer.displayName = 'HeaderContainer';

export const HeaderOpenBankingContainer = styled.header`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: right;
`;

HeaderOpenBankingContainer.displayName = 'HeaderOBContainer';
