import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Button from '@nubank/nuds-web/components/Button/Button';

export const CTAButton = styled(Button)`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  position: fixed;
  bottom: 0;
  width: calc(100vw - 3rem);
  left: 0;

  ${breakpointsMedia({
    md: css`
      position: initial;
      width: auto;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: 1rem;
    `,
  })}
`;

CTAButton.displayName = 'CTAButton';
