import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import { SiteContext } from 'components/SiteContext/SiteContext';

import { StyledImg } from '../../styles/Img';
import { StyledButton } from '../../styles/StyledButton';
import { Subtitle, ImageContainer } from '../../styles/CustomStyles';

const FeedbackEmail = ({
  prospectEmail,
  prospectNames,
}) => {
  const { closeRegistrationForm, loadingTime } = useContext(SiteContext);

  useEffect(() => {
    sendEvent('REALTIME_ANALYSIS_TIMEOUT', { loadingTime: loadingTime.current });
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="center" css={ImageContainer}>
        <FormattedMessage id="PROSPECT_REGISTRATION_FORM.DECISION_STEP.IMAGE.ALT">
          { msg => (
            <StyledImg
              src={requireImage('registration/handEmail.svg')}
              alt={msg}
            />
          )}
        </FormattedMessage>
      </Box>
      <Typography
        variant="heading3"
        data-testid="FeedbackEmailTitle"
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.TITLE"
        intlValues={{
          names: (
            <Typography
              color="primary"
              variant="heading3"
              tag="span"
              colorVariant="default"
            >
              {prospectNames}
            </Typography>
          ),
        }}
        gutterBottom={1}
      />
      <Typography
        variant="subtitle1"
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.SUBTITLE"
        gutterBottom={4}
        css={Subtitle}
        intlValues={{
          br: <br />,
          email: (
            <Typography
              color="black"
              strong
              variant="subtitle1"
              tag="span"
            >
              {prospectEmail}
            </Typography>
          ),
          purpleFuture: (
            <Typography
              color="primary"
              strong
              intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.SUBTITLE.PURPLE_FUTURE"
              variant="subtitle1"
              tag="span"
              colorVariant="default"
            />
          ),
        }}
      />
      <StyledButton
        id="decision-timeout-step-close-btn"
        intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.BUTTON.LABEL"
        type="submit"
        onClick={closeRegistrationForm}
        extended
      />
    </>
  );
};

FeedbackEmail.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
  prospectNames: PropTypes.string.isRequired,
};

export default FeedbackEmail;
