import styled from 'styled-components';

export const Head = styled.div`
  position: relative;
`;

Head.displayName = 'Head';

export const HeadImage = styled.div`
  max-width: 221px;
  margin: 0 auto;
  margin-bottom: 16px;
`;

HeadImage.displayName = 'HeadImage';
