import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';

import {
  StyledCallout, StyledCollapse, CollapseContent, InnerCallout, Styledline, StyledDescription,
} from './styles/CalloutStyle';

const Callout = ({
  verticalAccordionProps,
}) => {
  const {
    title, initiallyOpen, items, notice,
  } = verticalAccordionProps;
  return (
    <StyledCallout>
      <Box
        tag="section"
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        marginTop={{ md: '0', lg: '0' }}
      >
        <StyledCollapse
          id={title}
          initiallyOpen={initiallyOpen}
        >
          <Collapse.Header>
            <Typography
              color="black.light"
              variant="subtitle2"
              strong
              intlKey={title}
            />
          </Collapse.Header>
          <Collapse.Body>
            {items.map(({ itemTitle, description }) => (
              <CollapseContent>
                <Typography
                  color="black.light"
                  variant="subtitle1"
                  strong
                  intlKey={itemTitle}
                />
                <Styledline />
                <StyledDescription
                  color="black.light"
                  variant="paragraph2"
                  intlKey={description}
                />
              </CollapseContent>
            ))}
            <InnerCallout>
              <Typography
                color="black.light"
                variant="paragraph2"
                intlKey={notice}
              />
            </InnerCallout>
          </Collapse.Body>
        </StyledCollapse>

      </Box>
    </StyledCallout>
  );
};

Callout.defaultProps = {
  verticalAccordionProps: {
    initiallyOpen: false,
  },
};

Callout.propTypes = {
  verticalAccordionProps: PropTypes.shape({
    initiallyOpen: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
      itemTitle: PropTypes.string.isRequired,
    })).isRequired,
    notice: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default Callout;
