import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { getDocumentsCo } from '../domains/legalDocuments/getDocuments';

const filesPerKey = [{ key: undefined, files: ['nu_SA_data_policy', 'nu_CF_data_policy', 'whatsapp_policy', 'nu_SA_authorization_policy', 'nu_CF_authorization_policy'] },
  { key: 'HOME', files: ['nu_SA_data_policy', 'nu_CF_data_policy', 'whatsapp_policy', 'nu_SA_authorization_policy', 'nu_CF_authorization_policy'] },
  { key: 'MULTI_PRODUCT_HOME', files: ['nu_SA_data_policy', 'nu_CF_data_policy', 'whatsapp_policy', 'nu_SA_authorization_policy', 'nu_CF_authorization_policy'] },
  { key: 'CREDIT_CARD', files: ['nu_SA_data_policy', 'whatsapp_policy', 'nu_SA_authorization_policy', 'nu_CF_authorization_policy'] },
  { key: 'CREDIT_CARD_CONDITIONS', files: ['nu_SA_data_policy'] },
  { key: 'SAVINGS', files: ['nu_SA_data_policy', 'nu_CF_data_policy', 'whatsapp_policy', 'nu_SA_authorization_policy', 'nu_CF_authorization_policy'] },
  { key: 'SAVINGS_CONDITIONS', files: ['nu_CF_data_policy'] },
  { key: 'MULTI_PRODUCT_CREDIT_CARD_CONTRACT', files: ['nu_CF_data_policy'] }];

export const useGetDocumentsByRoute = () => {
  const route = useRouter();
  const [documents, setDocuments] = useState([]);

  const fetchDocuments = async listDocuments => {
    try {
      let documentsResponse = await getDocumentsCo('all', 'latest');
      documentsResponse = documentsResponse.filter(
        ({ name }) => listDocuments.files.includes(name),
      );

      return setDocuments(documentsResponse);
    } catch {
      return [];
    }
  };

  useEffect(() => {
    const listDocuments = filesPerKey.find(({ key }) => route.query?.intlKey === key);
    fetchDocuments(listDocuments);
  }, []);

  return documents;
};
