import styled from 'styled-components';
import { desktop1024 } from '@nubank/nuds-web/styles/breakpoints';

export const StepWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem;
  overflow-y: visible;
  height: 100%;

  ${desktop1024`
    padding: 0 2.5rem 2.5rem;
  `}
`;

StepWrapper.displayName = 'StepWrapper';

export const LoadingStepWrapper = styled(StepWrapper)`
    justify-content: center;
    height: calc(100vh - 9rem);
    align-items: center;
`;

LoadingStepWrapper.displayName = 'LoadingStepWrapper';

export const ResultStepWrapper = styled(StepWrapper)`
    padding-bottom: 0;
    width: 100%;
    height: 100%;

`;

ResultStepWrapper.displayName = 'ResultStepWrapper';
