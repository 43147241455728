import React, { useContext } from 'react';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { SiteContext } from '../../components/SiteContext/SiteContext';
import ProspectRegistrationForm from '../../screens/Registration/ProspectRegistrationForm/ProspectRegistratIonForm';
import ProspectRegistrationOBForm from '../../screens/Registration/ProspectRegistrationForm/ProspectRegistratIonOBForm';
import { OB } from '../../utils/experiments';

const RegistrationDrawer = () => {
  const activeVariant = useFigPiiExperiment(OB.id);

  const {
    isRegistrationFormOpen,
    closeRegistrationForm,
    usePurpleAsBackgroundInRegistrationForm,
  } = useContext(SiteContext);

  const approvedScreenStyleProps = usePurpleAsBackgroundInRegistrationForm ? {
    backgroundColor: '#820AD1',
  } : {};

  return (
    <Drawer
      onClose={closeRegistrationForm}
      open={isRegistrationFormOpen}
      id="prospect-registration-form"
    >
      {({ DrawerContent }) => (
        <Box {...approvedScreenStyleProps} height="100%">
          <DrawerContent>
            {activeVariant === OB.variantOpenBanking
              ? <ProspectRegistrationOBForm />
              : <ProspectRegistrationForm />}
          </DrawerContent>
        </Box>
      )}
    </Drawer>
  );
};

export default RegistrationDrawer;
