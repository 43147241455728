import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import RouterLink from 'components/RouterLink/RouterLink';

import CucMenu from '../CucMenu/CucMenu';

import CucMenuHeaderProps from './variant/CucMenuHeaderProps';
import {
  MenuWrapper, LogoWrapper, ActionsWrapper, StyledChip,
} from './styles/CucMenuHeaderStyles';

const {
  menuArray,
} = CucMenuHeaderProps;

const CucMenuHeader = ({
  CTA,
  nuLogoHref,
  showCTA,
  showMenuBtn,
  showHeaderTopMenu,
}) => {
  const router = useRouter();
  const { route } = router;
  const { formatMessage } = useIntl();

  const toggleMobileMenu = isMenuMobileOpen => {
    const eventLabel = isMenuMobileOpen ? 'open_menu' : 'close_menu';
    sendEvent(eventLabel);
  };

  const Logo = () => <NuLogo variant="primary" size="small" />;
  const logo = { link: nuLogoHref };

  return (
    <MenuWrapper>
      <CucMenu
        currentRoutePath={route}
        hideMobileMenu={!showMenuBtn}
        onToggleMobileMenu={isMenuMobileOpen => toggleMobileMenu(isMenuMobileOpen)}
        mobileButtonIconTitle={{
          titleClose: 'Cerrar Menu',
          titleOpen: 'Abrir Menu',
        }}
      >
        <CucMenu.Logo>
          {showHeaderTopMenu ? (
            <LogoWrapper>
              <RouterLink
                href={logo.link}
                onClick={() => sendEvent('logo')}
              >
                <Logo />
              </RouterLink>
            </LogoWrapper>
          ) : (
            <Logo />
          )}
        </CucMenu.Logo>
        <CucMenu.Actions>
          <ActionsWrapper>
            {showCTA && CTA}
          </ActionsWrapper>
        </CucMenu.Actions>

        {showHeaderTopMenu && (
          <CucMenu.Navigation>
            {({
              Section,
              SectionTitle,
              SectionLink,
              Column,
              ColumnTitle,
              ColumnLink,
            }) => (
              <>
                {menuArray.map(({ menuType, ...group }) => (
                  menuType === 'menu_link' ? (
                    <Section>
                      <SectionLink
                        href={formatMessage({ id: group.link })}
                        onClick={() => sendEvent(group.analyticsEvent)}
                        iconProps={group.showArrow ? {
                          name: 'arrow-up-right',
                          size: 'default',
                        } : {}}
                      >
                        {formatMessage({ id: group.text })}
                      </SectionLink>
                    </Section>
                  ) : (
                    <Section>
                      <SectionTitle>{formatMessage({ id: group.title })}</SectionTitle>
                      {group.subcategories.map(subcategory => (
                        <Column>
                          <ColumnTitle>{formatMessage({ id: subcategory.title })}</ColumnTitle>
                          {subcategory.links.map(link => (
                            <ColumnLink
                              href={formatMessage({ id: link.link })}
                              onClick={() => sendEvent(link.analyticsEvent)}
                              afterComponent={() => link.new && (
                                <StyledChip
                                  backgroundColor="primary.defaultL10"
                                  text={formatMessage({ id: 'CUCUTA.MENU.NEW_CHIP_LABEL' })}
                                />
                              )}
                            >
                              {formatMessage({ id: link.text })}
                            </ColumnLink>
                          ))}
                        </Column>
                      ))}
                    </Section>
                  )
                ))}
              </>
            )}
          </CucMenu.Navigation>
        )}
      </CucMenu>
    </MenuWrapper>
  );
};

CucMenuHeader.defaultProps = {
  CTA: false,
  nuLogoHref: '/',
  showCTA: false,
  showMenuBtn: false,
  showHeaderTopMenu: true,
};

CucMenuHeader.propTypes = {
  CTA: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.node]),
  nuLogoHref: PropTypes.string,
  showCTA: PropTypes.bool,
  showHeaderTopMenu: PropTypes.bool,
  showMenuBtn: PropTypes.bool,
};

export default React.memo(CucMenuHeader);
