import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 2rem 2rem 0 2.5rem;
  background-color: white;
`;

HeaderWrapper.displayName = 'HeaderWrapper';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export const TitleWrapper = styled.div`
  display: flex;
`;

TitleWrapper.displayName = 'TitleWrapper';

export const Styledline = styled.hr`
width: 100%;
height: 1px;
background-color: #E1E1E1;
`;

Styledline.displayName = 'Styledline';
