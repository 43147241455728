import styled from 'styled-components';
import { tablet768 } from '@nubank/nuds-web/styles/breakpoints';
import Footer from '@nubank/nuds-web/patterns/Footer/Footer';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const StyledFooter = styled(Footer)`
  background-color: ${nuDSColor('white')};
  padding: 2rem 1.5rem 5rem;
  border-top: 20px black;
  border-top-color: black;
  margin-bottom: 2.5rem;

  ${tablet768`
    padding: 1em 3.5rem 0;
    border-top: 2px;
  `}
`;

StyledFooter.displayName = 'StyledFooter';

