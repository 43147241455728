import styled, { css } from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { nuDSColor, spacing } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

import Chip from '@nubank/www-latam-commons/components/Chip/Chip';

export const MenuWrapper = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
`;

export const ActionsWrapper = styled(Box)`
  li:nth-child(n+1):last-child {
    margin-left: ${spacing('4x')};
  }

  height: 100%;
  width: 100%;
  min-width: 100px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-end;
`;

export const LogoWrapper = styled(Box)`
  line-height: 0;
`;

export const StyledChip = styled(Chip)`
  font-size: .875rem;
  display: inline-block;
  padding: ${spacing('1x', '3x')};
  margin-left: ${spacing('2x')};
  cursor: default;

  ${breakpointsMediaUp({
    md: css`
    vertical-align: top;
    `,
  })}

  span {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  &:hover,
  &:focus {
    ${({ backgroundColor }) => backgroundColor
    && css`
      background-color: ${nuDSColor(backgroundColor)};
    `}
  }
`;

StyledChip.defaultProps = {
  backgroundColor: 'primary.defaultL10',
};

MenuWrapper.displayName = 'MenuWrapper';
ActionsWrapper.displayName = 'ActionsWrapper';
LogoWrapper.displayName = 'LogoWrapper';
StyledChip.displayName = 'StyledChip';
