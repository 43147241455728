import React from 'react';

import { StyledSVG } from './styles/StyledSVG';

const ColombiaFlag = () => (
  <StyledSVG width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0" width="24" height="12" fill="#fcd116" />
    <rect y="12" width="24" height="6" fill="#003893" />
    <rect y="18" width="24" height="6" fill="#ce1126" />
  </StyledSVG>

);

export default ColombiaFlag;
