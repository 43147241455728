import {
  registerProspect as sendProspectApplication, startRealtimeAnalysis, getPolling,
} from '@nubank/www-latam-commons/services/prospect';
import { createProspectFromForm } from 'utils/form/createProspectFromForm';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

export async function registerProspect({
  formValues,
  prospectType,
  isLHFActive,
  isOBActive,
  legalDocuments,
  OBDecisionType,
  onAnalysisStart,
  onAnalysisFinish,
  onApplicationFinish,
}) {
  const prospect = createProspectFromForm({
    ...formValues,
  });

  const getDocumentsSigned = (documents, whatsappAccepted) => {
    const documentsSigned = [];

    documents?.forEach(legalDocument => {
      if (legalDocument.name === 'whatsapp_policy' && !whatsappAccepted) {
        return;
      }

      documentsSigned.push({
        name: legalDocument.name.replaceAll('_', '-'),
        version: legalDocument.version,
      });
    });

    return documentsSigned;
  };

  const isWhatsappAccepted = () => formValues.whatsappAccepted !== undefined;
  const isLegalDocuments = () => legalDocuments && legalDocuments.length > 0;

  const setDataPolicy = () => {
    const whatsappAccepted = isWhatsappAccepted() ? formValues.whatsappAccepted : false;

    return { data_policy_documents_signed: getDocumentsSigned(legalDocuments, whatsappAccepted) };
  };

  const additionalParameters = {
    ...(isOBActive && { ob: OBDecisionType }),
    ...(isLHFActive && { lhf: isLHFActive }),
    ...(isWhatsappAccepted() && { opt_in: formValues.whatsappAccepted }),
    ...(isLegalDocuments() && setDataPolicy()),
  };

  const parameters = [prospectType, prospect, additionalParameters];

  const {
    hasThrottle,
    marketingId,
    hasRealtimeAnalysis,
    realtimeUrl,
  } = await sendProspectApplication(...parameters);

  sendEvent('PROSPECT_REGISTRATION_WAITLIST_SENT');

  if (!hasThrottle && hasRealtimeAnalysis) {
    onAnalysisStart();
    const polling = await getPolling(realtimeUrl);

    if (polling) {
      const analysisResult = await startRealtimeAnalysis({
        url: realtimeUrl,
        polling,
      });

      if (analysisResult && analysisResult.template) {
        onAnalysisFinish({
          analysisResult,
          prospect: {
            prospectType: prospectType.type,
            marketingId,
          },
        });
      } else {
        onAnalysisFinish(false);
      }

      return { marketingId };
    }

    onAnalysisFinish(false);

    return { marketingId };
  }

  onApplicationFinish(false);

  return { marketingId };
}
