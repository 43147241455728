import React, { useContext } from 'react';
import Button from '@nubank/nuds-web/components/Button/Button';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { SiteContext } from '../../../../../components/SiteContext/SiteContext';

import { HeaderContainer, HeaderOpenBankingContainer } from './styles/HeaderContainer';

const FormHeaderOpenBanking = ({ currentStep }) => {
  const { closeRegistrationForm, isApprovedScreenOpen } = useContext(SiteContext);
  const { formatMessage } = useIntl();

  const isOpenBankingStep = currentStep === 2;

  return (
    <>
      {isOpenBankingStep
        ? (
          <HeaderOpenBankingContainer>
            <Button
              variant="basic"
              styleVariant="primary"
              iconProps={{
                name: 'question-mark-circle', title: 'Ayuda',
              }}
              onClick={() => {
                sendEvent('OPEN_BANKING_HELP_BUTTON');
                window.open('https://blog.nu.com.co/open-finance-que-es-y-como-funciona/', '_blank');
              }}
            />
          </HeaderOpenBankingContainer>
        )
        : (
          <HeaderContainer>
            <Button
              data-testid="close-btn"
              variant="basic"
              styleVariant={isApprovedScreenOpen ? 'white' : 'primary'}
              onClick={closeRegistrationForm}
              iconProps={{
                name: 'x', title: formatMessage({ id: 'FORM.HEADER.CLOSE_BUTTON.TITLE' }),
              }}
            />
          </HeaderContainer>
        )}
    </>
  );
};

FormHeaderOpenBanking.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default FormHeaderOpenBanking;
